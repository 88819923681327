import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ReportState = {
    reportKey: string | null;
};

const initialState: ReportState = {
    reportKey: null
};

const saveReportKey = async (reportKey: string | null): Promise<void> => {
    if (reportKey) {
        await sessionStorage.setItem('reportKey', reportKey);
    } else {
        await sessionStorage.removeItem('reportKey');
    }
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setCredentials: (state, { payload: { reportKey } }: PayloadAction<ReportState>) => {
            saveReportKey(reportKey);
            state.reportKey = reportKey;
        },
    },
});

export const { setCredentials } = reportSlice.actions;

export default reportSlice.reducer;
