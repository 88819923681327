import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface ListParams {
  [field: string]: string | number;
}
export const baseUrl = "https://api-thi.overcloud.usermd.net/";

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    const token = await getAuthToken();
    if (token) {
      headers.set("Authorization", token);
    }
    return headers;
  },
});

export const getAuthToken = async (): Promise<string | null> =>
  sessionStorage.getItem("reportKey");

export const parseListParams = (
  baseUrl: string,
  params: ListParams
): string => {
  const parsed: string[] = [];
  Object.keys(params).forEach((param: string) => {
    parsed.push(`${param}=${params[param]}`);
  });
  return parsed.length > 0 ? `${baseUrl}?${parsed.join("&")}` : baseUrl;
};
