import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCredentials } from "../features/reportSlice";
import { useCheckReportKeyMutation } from "../services/report.service";

export default function AuthPage() {
    const [reportKey, setReportKey] = useState('');
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const [checkReportKey, { isLoading }] = useCheckReportKeyMutation();

    const checkKey = async () => {
        try {
            if (reportKey) {
                const res = await checkReportKey({ reportKey }).unwrap();
                if (res && res.success) {
                    dispatch(setCredentials({ reportKey }));
                } else {
                    setMessage('Nieprawidłowy klucz dostępu');
                }
            } else {
                setMessage('Podaj klucz dostępu');
            }
        } catch (err) {
            setMessage('Nieprawidłowy klucz dostępu');
        }
    }

    const onKeyChange = (value: string) => {
        setMessage('');
        setReportKey(value);
    }

    return (<div className="row">
        <main className="col-md-12 col-lg-12 px-md-4 mt-5">
            <label htmlFor="reportKeyInput" className="form-label">Klucz dostępu</label>
            <input type="password" value={reportKey} onChange={(e) => onKeyChange(e.target.value)} className="form-control" id="reportKeyInput" placeholder="Podaj klucz dostępu" disabled={isLoading} />
            {message && <p className="text-danger mt-3">{message}</p>}
            <button className="btn btn-primary mt-2" disabled={isLoading} onClick={checkKey}>Zatwierdź</button>
        </main>
    </div>)
}