import './App.css';
import { useAppSelector } from './hooks/rtk.hooks';
import AuthPage from './pages/Auth.page';
import ReportTable from './pages/ReportTable.page';

function App() {
  const reportKey = useAppSelector((state) => state.report.reportKey);
  if (reportKey) {
    return <ReportTable />
  } else {
    return <AuthPage />
  }
}

export default App;
