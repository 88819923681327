import { configureStore } from '@reduxjs/toolkit';

import reportReducer from '../features/reportSlice';
import { reportApi } from '../services/report.service';

export const store = configureStore({
    reducer: {
        report: reportReducer,
        [reportApi.reducerPath]: reportApi.reducer,
    },
    devTools: true,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(reportApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
