import { floor, round, sortBy, uniq } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { RegionReport, RegionReportLog } from "../interface/report.interface";
import { useGetAvailibleDatesQuery, useLazyGetReportQuery } from "../services/report.service";

const MONTH_NAMES: Record<number, string> = {
    1: 'Styczeń',
    2: 'Luty',
    3: 'Marzec',
    4: 'Kwiecień',
    5: 'Maj',
    6: 'Czerwiec',
    7: 'Lipiec',
    8: 'Sierpień',
    9: 'Wrzesień',
    10: 'Październik',
    11: 'Listopad',
    12: 'Grudzień'
}

const regionNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export default function ReportTable() {
    const [showLegend, setShowLegend] = useState(true);
    const [month, setMonth] = useState<string>(moment().format("MM"));
    const [year, setYear] = useState<string>(moment().format("YYYY"));
    const [report, setReport] = useState<RegionReport[]>([]);

    const { data: availibleDatesData, isLoading: availibleDatesLoading } = useGetAvailibleDatesQuery();
    const [getReport, { isLoading: getReportLoading }] = useLazyGetReportQuery();

    useEffect(() => {
        fetchReport();
    }, [month, year])

    const fetchReport = async () => {
        try {
            if (month && year) {
                const res = await getReport({ date: `${month}.${year}` }).unwrap();
                if (res && res.success) {
                    setReport(res.report);
                }
            }
        } catch (err) {
            alert("Błąd pobierania raportu")
        }
    }

    const renderAvailibleMonths = () => {
        if (availibleDatesData && availibleDatesData.dates) {
            const availbileMonths = availibleDatesData.dates.filter((date) => {
                const dateYear = parseInt(date.split('.')[1]);
                return dateYear.toString() === year;
            }).map((date) => {
                const monthData = parseInt(date.split('.')[0]);
                return monthData;
            });
            return uniq(availbileMonths).map((monthData, index) => {
                return <li key={`year(${index})`}><a className="dropdown-item d-flex justify-content-between align-items-center" href="#" onClick={() => setMonth(monthData.toString())}>{MONTH_NAMES[monthData] || monthData}</a></li>
            })
        }
        return null;
    }

    const renderAvailibleYears = () => {
        if (availibleDatesData && availibleDatesData.dates) {
            const availbileYears = availibleDatesData.dates.map((date) => {
                const yearData = parseInt(date.split('.')[1]);
                return yearData;
            });
            return uniq(availbileYears).map((yearData, index) => {
                return <li key={`year(${index})`}><a className="dropdown-item d-flex justify-content-between align-items-center" href="#" onClick={() => setYear(yearData.toString())}>{yearData}</a></li>
            })
        }
        return null;
    }

    const getBgClass = (termStressFactor: number, smsType: string | null): string => {
        let colClass = '';
        if (termStressFactor < 68) {
            colClass = 'color1';
        } else if (termStressFactor >= 68 && termStressFactor <= 71) {
            colClass = 'color2';
        } else if (termStressFactor >= 72 && termStressFactor <= 78) {
            colClass = 'color3';
        } else if (termStressFactor >= 79 && termStressFactor <= 89) {
            colClass = 'color4';
        } else if (termStressFactor >= 90 && termStressFactor <= 104) {
            colClass = 'color5';
        }

        if (smsType) {
            switch (smsType) {
                case 'start': {
                    colClass += ' border1';
                    break;
                }
                case 'continue': {
                    colClass += ' border2';
                    break;
                }
                case 'end': {
                    colClass += ' border3';
                    break;
                }
            }
        }
        return colClass;
    }

    const fixedLengthNumber = (value: number): string => {
        return ("0" + value).slice(-2);
    }

    const renderTableData = () => {
        if (availibleDatesLoading || getReportLoading) {
            return <tr>
                <td colSpan={33}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Ładowanie</span>
                    </div>
                </td>
            </tr>
        }

        if (report.length) {
            const daysInMonth = moment(`${month}.${year}`, 'MM.YYYY').daysInMonth();
            return sortBy(report, (r) => r._id).map((r, index) => {
                let termStressDays = 0;
                r.logs.forEach((log) => {
                    if (log.weatherLog.stressFactor >= 68) termStressDays++;
                })
                const termStressDaysPercentage = round((termStressDays / daysInMonth) * 100);
                const labelStyle = getBgClass(termStressDaysPercentage, null);

                const cols = [
                    <td key={`${index}percentage`} className="d-flex justify-content-between align-items-center">Region {r._id}<span className={`badge ${labelStyle}`}>{termStressDaysPercentage}%</span></td>,
                    <td key={`${index}below`}>{r.logs.length - termStressDays}</td>,
                    <td key={`${index}above`}>{termStressDays}</td>
                ];

                for (let dayNumber = 1; dayNumber < 32; dayNumber++) {
                    const dayDate = `${fixedLengthNumber(dayNumber)}.${fixedLengthNumber(parseInt(month))}.${year}`;
                    let dayLog = r.logs.find((log) => log.dateFormatted === dayDate);
                    if (dayLog) {
                        const termStressFactor = floor(dayLog.weatherLog.stressFactor);
                        const smsType = dayLog.smsLogs.length ? dayLog.smsLogs[0].smsType : null;
                        const columnClass = getBgClass(termStressFactor, smsType);
                        cols.push(<td key={`${index}log${dayDate}`} scope="col" className={columnClass}>{termStressFactor}</td>);
                    } else {
                        cols.push(<td key={`${index}log${dayDate}`} scope="col">-</td>);
                    }
                }
                return (<tr key={`${r._id}row`}>
                    {cols}
                </tr>)
            });
        }
        return <tr>
            <td colSpan={33} className="text-center">
                <p>Brak danych do wyświetlenia</p>
            </td>
        </tr>
    }

    return (<div className="row">
        <main className="col-md-12 col-lg-12 px-md-4 mt-5">
            <h3 className="h5 mb-4"><span className="me-3">&lt;</span> Podsumowanie</h3>
            <h1 className="h2 mb-1">Wybierz datę</h1>
            <div className="row">

                <div className="d-flex col-12 justify-content-between align-items-center border-bottom pb-3 pt-2 mb-3">
                    <div className="d-flex flex-wrap flex-md-nowrap align-items-center gap-3">
                        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle rounded-5 p-2 px-3" data-bs-toggle="dropdown" aria-expanded="false">
                            {year}
                        </button>
                        <ul className="dropdown-menu">
                            {renderAvailibleYears()}
                        </ul>
                        <div className="dropdown">
                            <button className="btn btn-sm btn-outline-secondary rounded-5 p-2 px-3 dropdown-toggle" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {MONTH_NAMES[parseInt(month)] || month}
                            </button>
                            <ul className="dropdown-menu">
                                {renderAvailibleMonths()}
                            </ul>
                        </div>
                        <div className="form-check mb-0">
                            <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked" checked={showLegend} onChange={(e) => setShowLegend(e.target.checked)} />
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Legenda
                            </label>
                        </div>
                    </div>

                </div>
                <div className="d-flex col-12 justify-content-between align-items-center border-bottom pb-3 pt-2 mb-3">
                    {showLegend && <><div className="card">
                        <div className="card-body d-flex flex-row gap-5 align-items-center">
                            <div className="text-center fw-bold border-end pe-5">
                                Zakres THI
                            </div>
                            <div><span className="colordot color1"></span>≤ 67</div>
                            <div><span className="colordot color2"></span>68 - 71</div>
                            <div><span className="colordot color3"></span>72 - 78</div>
                            <div><span className="colordot color4"></span>79 - 89</div>
                            <div><span className="colordot color5"></span>90 - 104</div>
                        </div>
                    </div>
                        <div className="card">
                            <div className="card-body d-flex flex-row gap-5 align-items-center">
                                <div className="text-center fw-bold border-end pe-5">
                                    Wysłany SMS
                                </div>
                                <div><span className="colordiv border1"></span>Start</div>
                                <div><span className="colordiv colordiv2 border2"></span>Kontynuacja</div>
                                <div><span className="colordiv border3"></span>Stop</div>
                            </div>
                        </div></>}
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-sm">
                    <thead className="colorbg">
                        <tr>
                            <th scope="col">Region</th>
                            <th scope="col">THI &lt; 72</th>
                            <th scope="col">THI &gt; 72</th>
                            <th scope="col" className="light">1</th>
                            <th scope="col" className="light">2</th>
                            <th scope="col" className="light">3</th>
                            <th scope="col" className="light">4</th>
                            <th scope="col" className="light">5</th>
                            <th scope="col" className="light">6</th>
                            <th scope="col" className="light">7</th>
                            <th scope="col" className="light">8</th>
                            <th scope="col" className="light">9</th>
                            <th scope="col" className="light">10</th>
                            <th scope="col" className="light">11</th>
                            <th scope="col" className="light">12</th>
                            <th scope="col" className="light">13</th>
                            <th scope="col" className="light">14</th>
                            <th scope="col" className="light">15</th>
                            <th scope="col" className="light">16</th>
                            <th scope="col" className="light">17</th>
                            <th scope="col" className="light">18</th>
                            <th scope="col" className="light">19</th>
                            <th scope="col" className="light">20</th>
                            <th scope="col" className="light">21</th>
                            <th scope="col" className="light">22</th>
                            <th scope="col" className="light">23</th>
                            <th scope="col" className="light">24</th>
                            <th scope="col" className="light">25</th>
                            <th scope="col" className="light">26</th>
                            <th scope="col" className="light">27</th>
                            <th scope="col" className="light">28</th>
                            <th scope="col" className="light">29</th>
                            <th scope="col" className="light">30</th>
                            <th scope="col" className="light">31</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableData()}
                    </tbody>
                </table>
            </div>
        </main>
    </div>)
}