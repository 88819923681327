import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../application/api';
import { CheckReportKeyRequest, CheckReportKeyRespone, GetAvailibleDatesResponse, GetThiReportRequest, GetThiReportResponse } from '../interface/report.interface';

export const reportApi = createApi({
    baseQuery,
    tagTypes: ['Report', 'Dates'],
    endpoints: builder => ({
        checkReportKey: builder.mutation<CheckReportKeyRespone, CheckReportKeyRequest>({
            query: (data) => ({
                url: '/report/checkReportKey',
                method: 'POST',
                body: data,
            }),
        }),
        getReport: builder.query<GetThiReportResponse, GetThiReportRequest>({
            query: ({ date }) => ({
                url: `/report/fullReport/${date}`,
                method: 'GET'
            }),
            providesTags: ["Report"]
        }),
        getAvailibleDates: builder.query<GetAvailibleDatesResponse, void>({
            query: () => ({
                url: '/report/availibleDates',
                method: 'GET'
            }),
            providesTags: ["Dates"]
        }),
    }),
});

export const { useGetReportQuery, useLazyGetReportQuery, useGetAvailibleDatesQuery, useLazyGetAvailibleDatesQuery, useCheckReportKeyMutation } = reportApi;
